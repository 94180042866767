export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'ka',
  // messages: {
  //   en: {
  //     welcome: 'Welcome',
  //   },
  //   fr: {
  //     welcome: 'Bienvenue',
  //   },
  // },
}))
